<template>
  <div class="flex-container non-scrollable">
    <div class="flex justify-content-between align-items-center pb-2">
      <page-title value="Nouvelle simulation"/>
      <div></div>
      <div class="flex" style="gap: 1rem" v-if="hasAccess($route.name, true)">
        <Button :label="$t('view', {target: $t('simulation').toLowerCase()})" class="p-button-rounded"
                :disabled="!canGenerateSimulation"  size="small" @click="generateSimulation"/>
        <Button :label="$t('save')" class="p-button-rounded" :disabled="!canSaveSimulation" size="small" @click="save" />
        <Button :label="$t('send')" class="p-button-rounded" :disabled="!canSendSimulation" size="small" @click="send" />
      </div>
    </div>
    <div class="scrollable">
      <fiche-simulation
          ref="ficheSimu"
          empty-simulation
          @canGenerate="(value) => canGenerateSimulation = value"
          @canSave="(value) => canSaveSimulation = value"
          @canSend="(value) => canSendSimulation = value"
      />
      <div class="spacer"/>
    </div>
    <Dialog :style="{ width: '65vw' }" :visible="showSimulation" :modal="true" @update:visible="showSimulation = false" :dismissable-mask="true" :draggable="false" class="pdfIframe" content-class="p-0">
      <iframe  width="100%" height="1200px" :src='pdf'/>
    </Dialog>
  </div>
</template>

<script>
import FicheSimulation from "@/components/FicheSimulation";
import PdfGenerator from "@/utils/PdfGenerator";
import Alert from '@/utils/Alert';
import {mapState} from "vuex";
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "CreateSimulation",
  components: {PageTitle, FicheSimulation},
  data () {
    return {
      hasAccess: hasAccess,
      test: '',
      pdf: null,
      showSimulation: false,
      doc: null,
      canGenerateSimulation: false,
      canSaveSimulation: false,
      canSendSimulation: false
    }
  },
  created () {
    this.$store.dispatch('simulation/clearAllSimulations')
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  computed: {
    ...mapState({
      simulation: state => state.simulation.simulation,
      settings: state => state.misc.settings,
      constants: state => state.constants
    })
  },
  methods: {
    // todo: in case run generateSimulation can be activate before register the simulation insure that settings is properly set
    generateSimulation () {
      if (!this.canGenerateSimulation) return
      this.formatDates()

      this.$store.dispatch('simulation/generateSimulation', { id: this.simulation.id }).then((simu) => {
        this.simulationGenerated = simu.simulation
        this.pdfGenerator = new PdfGenerator({ ...this.simulationGenerated, settings: this.settings, constants: this.constants }, 'simulationV2')
        this.pdfGenerator.getPdf((dataUrl) => {
          this.pdf = dataUrl
          this.showSimulation = true
        })
      })
    },
    formatDates () {
      if (this.simulation.jsonClient && this.simulation.jsonClient.startingDate) {
        let date = new Date(this.simulation.jsonClient.startingDate)
        this.simulation.jsonClient.startingDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      }
      if (this.simulation.jsonClient && this.simulation.jsonClient.endingDate) {
        let date = new Date(this.simulation.jsonClient.endingDate)
        this.simulation.jsonClient.endingDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      }
    },
    async save () {
      if (!this.canSaveSimulation) return

      this.formatDates()

      if (!this.simulation.commercial) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(1)'})
      } else {
        await this.$store.dispatch('simulation/saveSimulation', this.simulation).then((result) => {
          Alert.successMessage(this, 'saveSimulation')
          this.$router.push({ name: 'FicheProspect', params: { idProspect: result.independent.id, idSimulation: result.id }} )
        })
      }
    },
    async send () {
      if (!this.canSendSimulation) return

      if (!(this.constants.REGEX_VALIDATORS.EMAIL.test(this.simulation.independent.email))) {
        Alert.errorMessage(this, 'invalidEmail')
        return
      }

      this.$store.state.misc.loading = true
      this.formatDates()
      try {
        await this.$store.dispatch('simulation/sendSimulation', { simulation: this.simulation, role: this.constants.ROLES.PROSPECT})
        Alert.successMessage(this, 'sendSimulation')
        this.$store.state.misc.loading = false
      } catch (error) {
        Alert.errorMessage(this, 'sendSimulation')
        this.$store.state.misc.loading = false
      }
    },
  }
}
</script>

<style>
.pdfIframe .p-dialog-content {
  padding: 0;
}

</style>
